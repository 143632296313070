import React from 'react'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

const ThankYouNewsLetter = () =>  (
  <Layout>
      <Seo title="Welcome to the club" />
      <div className="py-5 text-center">
      <h1 className="display-6 fw-bold">Now you are in!</h1>
      <div className="col-lg-11 mx-auto pt-3">
        <p className="lead">Our newsletters are monthly. Expect the next drop soon.</p>
      </div>
    </div>
  </Layout>
)

export default ThankYouNewsLetter